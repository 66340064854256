import {
  Container,
  Divider,
  Header,
  Image,
  Loader,
  Popup,
} from "semantic-ui-react";
import { useState, useEffect } from "react";
import classNames from "classnames";
import api from "../lib/api";
import styles from "./Sponsors.module.scss";
import Cache from "../lib/cache";

export default function Sponsors({
  className,
  hideTitle = false,
  isHomePage = false,
  isVertical = false,
}) {
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  useEffect(() => {
    const cacheKey = "sponsors_d";
    let isCanceled = false;
    const cached = Cache.get(cacheKey);

    if (cached) {
      setSponsors(cached);
      return;
    }

    setLoading(true);
    api
      .get("sponsors/active")
      .then((results) =>
        results.filter(({ sponsor_level: sl }) => sl === "PREMIER")
      )
      .then((premierSponsors) => {
        if (!isCanceled) {
          Cache.set(cacheKey, premierSponsors, 60 * 30);
          setSponsors(premierSponsors);
        }
      })
      .catch((err) => !isCanceled && setError(err.message))
      .finally(() => !isCanceled && setLoading(false));

    return () => {
      isCanceled = true;
    };
  }, []);

  const contain = (content) => (
    <Container
      fluid
      textAlign="center"
      className={classNames("sponsorLogos", className)}
    >
      {content}
    </Container>
  );

  if (loading) {
    return contain(<Loader />);
  }

  const sponsorContent = [];
  if (isHomePage && hideTitle !== false) {
    sponsorContent.push(
      <Divider key="dvdr" />,
      <Header
        key="hdr"
        color="orange"
        textAlign="center"
        as="h3"
        content="PREMIER SPONSORS"
      />
    );
  }

  const style = {};

  if (isVertical) {
    Object.assign(style, {
      maxHeight: "500px",
      flexDirection: "column",
      flexWrap: "wrap",
    });
  }

  const sponsorStyles = {
    margin: isHomePage ? "auto" : "3px",
  };
  if (!isHomePage) {
    sponsorStyles.filter = "grayscale(0)";
  }

  sponsorContent.push(
    <Image.Group
      key="sponsors"
      size="tiny"
      className={styles.SponsorGroup}
      style={style}
    >
      {sponsors.map(({ name, link, logo }) => (
        <Popup
          flowing
          header={name}
          key={link}
          trigger={
            <div
              className={styles.SponsorLogo}
              role="link"
              key={logo}
              tabIndex={0}
              title={`Visit ${name}`}
              onKeyPress={() => window.open(link)}
              onClick={() => window.open(link)}
              style={{
                ...sponsorStyles,
                backgroundImage: `url("${logo}")`,
              }}
            >
              {name}
            </div>
          }
        />
      ))}
    </Image.Group>
  );

  return contain(sponsorContent);
}
